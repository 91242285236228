import {useState, useEffect} from 'react';
import './Home.css';
import img_asperge from '../../Assets/asperge.webp';
import img_asperge_png from '../../Assets/asperge_epernay.webp';
import img_asperge_champs from '../../Assets/asperge_champs.webp';
import img_champs from '../../Assets/champs_maison_varnier_plivot.webp';
import img_emilien from '../../Assets/emilien_varnier.webp';
import img_entrepot from '../../Assets/entrepot_maison_varnier.webp';
import img_instagram from '../../Assets/instagram.png';
import img_facebook from '../../Assets/facebook.png';
import img_maison_varnier_logo from '../../Assets/maison_varnier.png';
import img_asperge_png_tel from '../../Assets/asperge_png_tel.png';

export default function Home() {

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    },[]);

    const opacity = Math.min(scrollPosition / 1000, 1);


    return (
        <div className="home-container">
            <div className='home-bg-1'></div>
            <div className='home-bg-2'></div>
            <div className='home-bg-3'></div>
            <div className='home-child'>
                <div className='home-first-element-container' >
                    <div className='home-first-element-left' style={{ transform: `translate(${-scrollPosition }px, ${-scrollPosition }px)`}}>
                        <p className='home-first-element-title'>
                            Les Asperges Fraiches de Maison Varnier
                        </p>
                        <p className='home-first-element-text'>
                            Découvrez la fraîcheur et la saveur exceptionnelle des asperges cultivées avec passion par Maison Varnier, près d'Épernay à Plivot. Nos asperges sont soigneusement sélectionnées et récoltées à leur apogée pour garantir une qualité inégalée à chaque bouchée.
                        </p>
                         <a href="#contact"><button className='home-button'>Contactez-nous</button></a>
                    </div>
                    <div className='home-first-element-right' style={{ transform: `translate(${scrollPosition }px, ${-scrollPosition }px)` }}>
                        <img alt="Asperge de Maison Varnier a coté de Epernay" className='home-first-element-right-img' src={img_asperge}>

                        </img>
                    </div>
                    <img className="home-logo" style={{ opacity:opacity}}alt="logo de l'entreprise Maison Varnier"src={img_maison_varnier_logo}></img>
                </div>
                <div className='home-first-element-container-tel' >
                    <div className='home-first-element-left' >
                        <p className='home-first-element-title'>
                            Les Asperges Fraiches de Maison Varnier
                        </p>
                        <p className='home-first-element-text'>
                            Découvrez la fraîcheur et la saveur exceptionnelle des asperges cultivées avec passion par Maison Varnier, près d'Épernay à Plivot. Nos asperges sont soigneusement sélectionnées et récoltées à leur apogée pour garantir une qualité inégalée à chaque bouchée.
                        </p>
                        <a href="#contact"><button className='home-button'>Contactez-nous</button></a>
                    </div>
                    <div className='home-first-element-right' >
                        <img alt="Asperge de Maison Varnier a coté de Epernay" className='home-first-element-right-img' src={img_asperge}>

                        </img>
                    </div>
                    
                </div>
                <div className='home-second-element-container'>
                    <div className='ligne'>

                    </div>
                
                    <div className='home-second-element'>
                        <img alt="asperge de près de epernay, à plivot" className='home-second-element-img'src={img_asperge_png} />
                        <div className='home-second-element-text'>
                            <p className='home-second-element-title'>
                                Fraîcheur inégalée
                            </p>
                            <p className='home-first-element-text_p'>
                            Dans notre quête de la perfection, chaque asperge est cueillie à la main à son pic de fraîcheur. Nous nous engageons à vous offrir des asperges qui captivent vos papilles avec leur goût vibrant et leur texture croquante. Découvrez la différence de la fraîcheur inégalée chez nous.
                            </p>
                            <a href="#tarif"> <button className='home-button'>Nos tarifs</button></a>
                        </div>
                        <img alt="asperge de près de epernay, à plivot" className='home-second-element-img-tel' id="asperge_caisse"src={img_asperge_png_tel} />
                    </div>
                    <div className='home-second-element'>
                        <div className='home-second-element-text'>
                            <p className='home-second-element-title'>
                            Qualité supérieure
                            </p>
                            <p className='home-first-element-text_p'>
                            Ici, la qualité est notre credo. Nos asperges sont cultivées avec dévouement selon les normes agricoles les plus strictes. Du plant à la récolte, nous prenons soin de chaque détail pour vous garantir une expérience culinaire exceptionnelle à chaque asperge. Choisissez la qualité supérieure et savourez l'excellence à chaque bouchée.
                            </p>
                            <a href="#contact"><button id="home-button-middle" className='home-button'>Ou nous trouver ?</button></a>
                        </div>
                        <img id="asperge_champs" alt="asperge de près de epernay, à plivot" className='home-second-element-img-tel'src={img_asperge_champs} />
                        <img id="asperge_champs" alt="asperge de près de epernay, à plivot" className='home-second-element-img'src={img_asperge_champs} />
                    </div>
                    <div className='home-second-element'>
                        <img id="img_champs" alt="asperge de près de epernay, à plivot" className='home-second-element-img'src={img_champs} />
                        <div className='home-second-element-text'>
                            <p className='home-second-element-title'>
                                Support local 
                            </p>
                            <p className='home-first-element-text_p'>
                            En optant pour nos asperges, vous soutenez l'économie locale à Épernay et ses environs. Notre ferme, située à Plivot, est un pilier de notre communauté, et chaque achat favorise une agriculture durable et soutient nos agriculteurs locaux. Choisissez-nous et participez au changement tout en savourant des asperges exceptionnelles.                            </p>
                            <a href="#contact"> <button id="home-button-middle" className='home-button'>Contactez-nous</button></a>
                            <img id="img_champs" alt="asperge de près de epernay, à plivot" className='home-second-element-img-tel'src={img_champs} />
                        </div>
                    </div>
                </div>
                <div className='home-third-element' id="tarif">
                    <div className='home-third-element-child'>
                <p className='home-second-element-title'>
                    Nos tarifs
                </p>
                <p className='home-first-element-text_p'>
                    Par kg
                </p>
                <div className='home-third-element-container-tarif'>
                    <div className='home-third-tarif'>
                        <p className='home-third-title'>
                            Catégorie 1
                        </p>
                        <div className='home-third-price-container'>
                        <p className='home-third-price'>
                                8
                        </p>
                        <p id="logo-euro-left"className='logo-euro'>€</p>
                        </div>
                    </div>
                   

                    <div className='home-third-tarif'>
                        <p className='home-third-title'>
                        Catégorie 2
                        </p>
                        <div className='home-third-price-container'>
                        <p className='home-third-price'>
                        7.50
                        </p>
                        <p className='logo-euro'>€</p>
                        </div>
                    </div>

                    <div className='home-third-tarif'>
                        <p className='home-third-title'>
                            Pointes d'asperges
                        </p>
                        <div className='home-third-price-container'>
                        <p className='home-third-price'>
                                10
                        </p>
                        <p className='logo-euro'>€</p>
                        </div>
                       
                    </div>
                    
                    <div className='home-third-tarif'>
                        <p className='home-third-title'>
                            Asperges vertes
                        </p>
                        <div className='home-third-price-container'>
                        <p className='home-third-price'>
                                10
                        </p>
                        <p className='logo-euro'>€</p>
                        </div>
                    </div>
                </div>
                </div>
                
                </div>
                <div className='home-fourth-element' id="contact">
                    <div className='home-fourth-container'>
                    <img  alt="asperge de près de epernay, à plivot" className='home-third-element-img-tel'src={img_emilien} />
                    <img  alt="asperge de près de epernay, à plivot" className='home-third-element-img'src={img_emilien} />
                        <div className='home-second-element-text'>
                           
                            <p className='home-fourth-element-text_p'>
                            Retrouvez nos délicieuses asperges fraîches dans notre ferme située au <strong> SCEA DES ÉPINETTES 1 Chemin de la Folie, 51150 PLIVOT</strong> près de Epernay.<br/>Nous sommes ouverts du lundi au dimanche pour vous accueillir chaleureusement et vous faire découvrir la fraîcheur et la qualité de nos produits.</p>
                           
                        </div>
                    </div>
                    <div className='home-fourth-container'>
                    <img  alt="asperge de près de epernay, à plivot" className='home-third-element-img-tel'src={img_entrepot} />
                        <div className='home-second-element-text'>
                         
                            <p className='home-fourth-element-text_p'>
                            Pour toute question, commande ou demande de renseignements supplémentaires, n'hésitez pas à nous contacter par e-mail à <strong>maisonvarnier@outlook.fr</strong> ou par téléphone au <strong>07 89 53 01 24</strong>.<br/>Que ce soit pour des informations sur nos produits ou des demandes spéciales, nous sommes impatients de vous entendre. </p>
                      
                        </div>
                        <img  alt="asperge de près de epernay, à plivot" className='home-third-element-img'src={img_entrepot} />
                    </div>
                </div>
                <div className='header-popup-main' >
                    <p className='header-popup-title'>Nos horaires d'ouverture</p>
                    <div className='header-popup-contenu'>
                        <p>Lundi</p>
                        <p>16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Mardi</p>
                        <p>10h-12h30 / 16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Mercredi</p>
                        <p>10h-12h30 / 16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Jeudi</p>
                        <p>16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Vendredi</p>
                        <p>10h-12h30 / 16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Samedi</p>
                        <p>10h-12h30 / 16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Dimanche</p>
                        <p>10h-12h30</p>
                    </div>
               
                </div>
                <div className='home-footer'>
                    <div className='home-footer-container-reseaux'>
                    <a target="blank" href="https://www.instagram.com/maisonvarnier?igsh=MXhwYm9wMm8xMDVrdA=="className='home-footer-a'><img alt="reseaux sociaux" src={img_instagram}></img></a>
                    <a href="#accueil" className='home-footer-a'><img alt="reseaux sociaux" src={img_facebook}></img></a>
                    </div>
                   
                    <p>© Copyright 2024 </p>
                    <p className='footer_text'> maisonvarnier@outlook.fr ·   07 89 53 01 24  · SCEA DES ÉPINETTES 1 Chemin de la Folie, 51150 PLIVOT </p>
                    <p className='footer_text_tel'> maisonvarnier@outlook.fr </p>
                    <p className='footer_text_tel'>   07 89 53 01 24 </p>
                    <p className='footer_text_tel'> SCEA DES ÉPINETTES 1 Chemin de la Folie, 51150 PLIVOT </p>
                    <div className='footer-barre'></div>
                    <p className='footer_text_tel_infos'>Besoin d'un site web ? Contactez-moi :</p>
                    <p className='footer_text_tel_infos'>gabin.cherquitte@gmail.com</p>
                    <p className='footer_text_infos'>Besoin d'un site web ? Contactez-moi :  gabin.cherquitte@gmail.com </p>

                </div>
            </div>
        </div>
    )
}