import {useState, useEffect} from 'react';
import './Header.css';
import img_maison_varnier_logo from '../../Assets/logo_maison_varnier.webp';

export default function Header() {

    const [popupActive, setPopupActive] = useState(false);
    const [popupActiveContact, setPopupActiveContact] = useState(false);

    const handlePopupClick = () => {
        setPopupActive(!popupActive);
        setPopupActiveContact(false);
    };

    const handlePopupContactClick = () => {
        setPopupActiveContact(!popupActiveContact);
        setPopupActive(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            setPopupActive(false);
            setPopupActiveContact(false);
        }
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    },[]);

    return (
        <div className="header-container">
            <div className='header-child'>
                <img className="header-logo" alt="logo de l'entreprise Maison Varnier"src={img_maison_varnier_logo}></img>
                <div className='header-container-menu'>
                    
                    <a href="#tarif"><p className='header-menu-p'>Tarifs</p></a>
                   <p onClick={handlePopupClick} className='header-menu-p'>Nos horaires</p>
                    <p onClick={handlePopupContactClick} className='header-menu-p'>Contactez-nous</p>
                </div>
                <div className='header-popup' style={{display: popupActive ? 'flex' : 'none'}}>
                    <p className='header-popup-title'>Nos horaires d'ouverture</p>
                    <div className='header-popup-contenu'>
                        <p>Lundi</p>
                        <p>16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Mardi</p>
                        <p>10h-12h30 / 16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Mercredi</p>
                        <p>10h-12h30 / 16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Jeudi</p>
                        <p>16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Vendredi</p>
                        <p>10h-12h30 / 16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Samedi</p>
                        <p>10h-12h30 / 16h-19h30</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Dimanche</p>
                        <p>10h-12h30</p>
                    </div>
               
                </div>
                <div className='header-popup-contact' style={{display: popupActiveContact ? 'flex' : 'none'}}>
                <p className='header-popup-title'>Nos informations</p>
                <div className='header-popup-contenu'>
                        <p>Mail </p>
                        <p>maisonvarnier@outlook.fr</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Tel </p>
                        <p>07 89 53 01 24</p>
                    </div>
                    <div className='header-popup-contenu'>
                        <p>Adresse</p>
                        <p>SCEA DES ÉPINETTES <br/> 1 Chemin de la Folie,<br/> 51150 PLIVOT</p>
                    </div>
                </div>
               
            </div>
            
            
        </div>
    )
}