import './App.css';
import Home from './Components/Home/Home';
import Header from './Components/Header/Header';

const HomeComponent = () => (
  <>
    <Header />
    <Home />
  </>
)

function App() {
  return (
    <div className="App">
        <HomeComponent />
    </div>
  );
}

export default App;
